import { graphql, PageProps } from "gatsby";
import React, { useState } from "react";
import { Breadcrumb } from "../components/Breadcrumb";
import { Heading01 } from "../components/Heading01";
import { Layout } from "../components/Layout/index";
import { FAQ01 } from "../components/FAQ01";
import { Tab01 } from "../components/Tab01";
import bgimgPc from "../images/h1/h1faq.png";
import bgimgSp from "../images/h1/h1faq_sp.png";

const FaqPage: React.VFC<PageProps<GatsbyTypes.IndexFaqQuery>> = ({ data }) => {
  const [selectedTab, setSelectedTab] = useState(1);

  // // 一覧から選ぶを押したときの動作
  const TabClicked01 = () => {
    if (selectedTab !== 1) {
      setSelectedTab(1);
    }
  };

  const TabClicked02 = () => {
    if (selectedTab !== 2) {
      setSelectedTab(2);
    }
  };

  const TabClicked03 = () => {
    if (selectedTab !== 3) {
      setSelectedTab(3);
    }
  };
  return (
    <Layout title="よくある質問" description="ミスギのよくある質問ページです。">
      <Heading01
        label="FAQ"
        smallLabel="よくある質問"
        bgimg={[bgimgPc, bgimgSp]}
      />
      <Breadcrumb labels={[{ url: "", text: "よくある質問" }]} />
      <div className="l-container-sm">
        {/* -----------------タブ------------- */}
        <div className="mt-16 mb-14 flex w-full flex-col tb:flex-row">
          <Tab01
            label="スロープ・コーナー"
            isSelected={selectedTab === 1}
            onClick={TabClicked01}
          />
          <Tab01
            label="カーストッパー"
            isSelected={selectedTab === 2}
            onClick={TabClicked02}
          />
          <Tab01
            label="その他"
            isSelected={selectedTab === 3}
            onClick={TabClicked03}
          />
        </div>
        <ul className="mt-8 mb-16 pc:mb-32">
          {selectedTab === 1 &&
            data.slopecorner.nodes.map(
              (node) =>
                node.title && (
                  <FAQ01
                    key={node.title}
                    question={node.title}
                    answer={node.content}
                  />
                )
            )}
          {selectedTab === 2 &&
            data.carstopper.nodes.map(
              (node) =>
                node.title && (
                  <FAQ01
                    key={node.title}
                    question={node.title}
                    answer={node.content}
                  />
                )
            )}
          {selectedTab === 3 &&
            data.others.nodes.map(
              (node) =>
                node.title && (
                  <FAQ01
                    key={node.title}
                    question={node.title}
                    answer={node.content}
                  />
                )
            )}
        </ul>
      </div>
    </Layout>
  );
};

export default FaqPage;

export const query = graphql`
  query IndexFaq {
    slopecorner: allWpFaq(
      filter: {
        terms: { nodes: { elemMatch: { slug: { eq: "slopecorner" } } } }
      }
    ) {
      nodes {
        title
        content
      }
    }
    carstopper: allWpFaq(
      filter: {
        terms: { nodes: { elemMatch: { slug: { eq: "carstopper" } } } }
      }
    ) {
      nodes {
        title
        content
      }
    }
    others: allWpFaq(
      filter: { terms: { nodes: { elemMatch: { slug: { eq: "others" } } } } }
    ) {
      nodes {
        title
        content
      }
    }
  }
`;
